<template lang="pug">
.container
  page-title(:title='`Reglas (${count})`')
    b-button(@click='editSelected' :disabled='!selected' type='is-primary' label='Editar' outlined)
    b-button.ml-2(@click='removeSelected' :disabled='!selected' :loading='isDeleting' label='Eliminar' type='is-primary' outlined)
    b-dropdown(:triggers='["hover"]' aria-role='list' position="is-bottom-left")
      template(#trigger)
        b-button.ml-2(type='is-primary' label='Nueva regla')
      b-dropdown-item(aria-role='listitem' has-link)
        router-link(:to='{ name: rulesZoneCreate.name }') Regla de zona
      b-dropdown-item(aria-role='listitem' has-link)
        router-link(:to='{ name: rulesOdometerCreate.name }') Regla de odómetro
      b-dropdown-item(aria-role='listitem' has-link)
        router-link(:to='{ name: rulesTimeCreate.name }') Regla de tiempos
      b-dropdown-item(aria-role='listitem' has-link)
        router-link(:to='{ name: rulesUsageCreate.name }') Regla de uso del equipo
  </b-dropdown>
  .card
    .card-content
      b-loading(is-full-page :active='isLoading')
      div(v-if='!isLoading')
        custom-table(
          class='rules-grid'
          id='rules'
          :column-defs='columnDefs'
          :row-data='rules'
          row-data-key='id'
          @row-selected='clientSelected'
          @link-clicked='clientClicked'
        )
</template>

<script>
import rulesService from '@/services/rules.service'
import CustomTable from '@/components/CustomTable'
import { rulesColumnDefs } from './constants'
import { ruleTypeDescriptions, ruleTypeEnum } from '@/constants/enums'
import {
  rulesZoneCreate,
  rulesZoneEdit,
  rulesOdometerCreate,
  rulesOdometerEdit,
  rulesTimeCreate,
  rulesTimeEdit,
  rulesUsageCreate,
  rulesUsageEdit
} from '@/router/routes'
import toastService from '@/services/toast.service'

export default {
  components: { CustomTable },
  data () {
    return {
      rulesZoneCreate,
      rulesOdometerCreate,
      rulesTimeCreate,
      rulesUsageCreate,
      rulesUsageEdit,
      isLoading: false,
      isDeleting: false,
      columnDefs: rulesColumnDefs,
      rules: [],
      selected: null
    }
  },
  created () {
    this.getRules()
  },
  computed: {
    count () {
      return this.rules?.length
    }
  },
  methods: {
    async getRules () {
      this.isLoading = true
      const result = await rulesService.getAll()
      if (result) {
        result.forEach(rule => {
          const devices = rule.devices.map(device => device.deviceName).join(', ')
          rule.typeDescription = ruleTypeDescriptions[rule.type]
          rule.devicesResume = `(${rule.devices.length}) ${devices}`
        })
        this.rules = result
      }
      this.isLoading = false
    },
    create () {
      this.selected = null
    },
    clientSelected (row) {
      this.selected = row.rowData ? { ...row.rowData } : null
    },
    clientClicked (row) {
      const rule = row?.rowData
      this.lauchEdit(rule)
    },
    editSelected () {
      this.lauchEdit(this.selected)
    },
    lauchEdit (rule) {
      if (rule) {
        switch (rule.type) {
          case ruleTypeEnum.Zone: {
            this.$router.push({ name: rulesZoneEdit.name, params: { id: rule.id } })
            break
          }
          case ruleTypeEnum.Odometer: {
            this.$router.push({ name: rulesOdometerEdit.name, params: { id: rule.id } })
            break
          }
          case ruleTypeEnum.Time: {
            this.$router.push({ name: rulesTimeEdit.name, params: { id: rule.id } })
            break
          }
          case ruleTypeEnum.Usage: {
            this.$router.push({ name: rulesUsageEdit.name, params: { id: rule.id } })
            break
          }
        }
      }
    },
    async removeSelected () {
      if (!await toastService.confirm(`¿Se eliminará permanentemente la regla "${this.selected.name}?"`)) return
      this.isDeleting = true
      const result = await rulesService.delete(this.selected)
      if (result) {
        toastService.show(`Se eliminó "${this.selected.name}"?`)
        this.rules = this.rules.filter(rule => rule.id !== this.selected.id)
        this.selected = null
      }
      this.isDeleting = false
    }
  }
}
</script>

<style lang="scss" scoped>
.rules-grid {
  height: calc(100vh - 210px);
}
</style>
